import { Children } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from '@dfds-ui/react-components/flexbox'
import { media, theme } from '@dfds-ui/theme'

import { AutoLink } from '../EntryLink'
import { gridMap, flexMap } from '../../utils'

const CardContainer = styled(FlexBox)`
  color: ${(props) =>
    props.dark
      ? theme.colors.text.light.primary
      : theme.colors.text.dark.primary};
  flex: 1 0 auto;
  height: 100%;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  justify-content: ${(props) =>
    props.reverse && props.mediaWidth === 12 ? 'space-between' : 'start'};

  ${media.lessThan('l')`
    flex-direction: column;
  `}
`

const MediaContainer = styled(FlexBox)`
  order: ${(props) => (props.reverse ? 2 : 'initial')};
  overflow: hidden;

  ${media.lessThan('l')`
    order: initial;
  `}
`

const TextFlexContainer = styled(FlexBox)`
  padding: ${(props) => (props.noPadding ? 0 : '20px')};
  color: ${(props) =>
    props.dark
      ? theme.colors.text.light.primary
      : theme.colors.text.dark.primary};
`

const TextContainer = styled.div`
  padding: ${(props) => (props.noPadding ? 0 : '20px')};
  color: ${(props) =>
    props.dark
      ? theme.colors.text.light.primary
      : theme.colors.text.dark.primary};
`

const LinkContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;
  padding: ${(props) => props.nobottompadding && '0'};
  padding: ${(props) => props.bottompadding && '0 20px 20px'};
  color: ${(props) =>
    props.dark
      ? theme.colors.text.light.primary
      : theme.colors.text.secondary.primary};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  a,
  a:visited {
    color: ${(props) =>
      props.dark
        ? theme.colors.text.light.primary
        : theme.colors.text.secondary.primary};
  }
  a,
  div {
    :not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

const getBackgroundColor = (dark, backgroundColor) => {
  if (dark) return theme.colors.primary.light
  return backgroundColor || theme.colors.surface.primary
}

const MediaRow = (props) => {
  return props.mediaWidth < 12 ? (
    <MediaContainer flex={flexMap(props.mediaWidth)} {...props}>
      {props.children}
    </MediaContainer>
  ) : (
    <div
      css={css`
        ${media.greaterThan('l')`
          order: ${props.reverse && 1};
        `}
      `}
    >
      {props.children}
    </div>
  )
}

const TextRow = (props) => {
  return props.textWidth < 12 ? (
    <TextFlexContainer
      {...props}
      directionColumn
      flex={flexMap(props.textWidth)}
    >
      {props.children}
    </TextFlexContainer>
  ) : (
    <TextContainer {...props}>{props.children}</TextContainer>
  )
}

const WrapAction = ({ link, children }) => {
  return link?.destination || link?.url ? (
    <AutoLink destination={link.destination} url={link.url}>
      {children}
    </AutoLink>
  ) : (
    <>{children}</>
  )
}

const FlexCard = (props) => {
  const {
    action,
    backgroundColor,
    width,
    mediaWidth,
    reverse,
    id,
    dark,
    media,
    children,
    horizontal,
    hide,
    noPadding,
    noSpacing,
    smallSpacing,
    topSpacing,
  } = props

  const mWidth =
    mediaWidth && typeof mediaWidth === 'object'
      ? mediaWidth.md
      : gridMap[mediaWidth] || 12
  const textWidth = mWidth && mWidth < 12 ? 12 - mWidth : 12
  const blockWidth = typeof width === 'object' ? width : gridMap[width]

  return (
    <div
      {...blockWidth}
      css={css`
        display: ${hide && 'none !important'};
        background-color: ${getBackgroundColor(dark, backgroundColor)};
        ${noSpacing && 'margin-bottom: 0 !important;'};
        ${smallSpacing && 'margin-bottom: 5px !important;'};
        ${topSpacing && 'margin-top: 20px'}
        margin-bottom: 20px;
      `}
      id={id}
    >
      <FlexBox
        css={css`
          flex-direction: column;
          flex: 1;
          height: 100%;

          p {
            ${noSpacing && 'padding: 0; margin: 0;'};
          }
        `}
      >
        <CardContainer
          horizontal={horizontal || textWidth !== 12}
          dark={dark}
          reverse={reverse}
          mediaWidth={mWidth}
        >
          <WrapAction {...props}>
            {media && (
              <MediaRow mediaWidth={mWidth} reverse={reverse}>
                {media}
              </MediaRow>
            )}
            {Children.toArray(children).length !== 0 && (
              <TextRow textWidth={textWidth} noPadding={noPadding} dark={dark}>
                {children}
                {mWidth < 12 && action && (
                  <LinkContainer dark={dark}>{action}</LinkContainer>
                )}
              </TextRow>
            )}
            {(!mWidth || mWidth === 12) && action && (
              <LinkContainer dark={dark} bottompadding={!noPadding}>
                {action}
              </LinkContainer>
            )}
          </WrapAction>
        </CardContainer>
      </FlexBox>
    </div>
  )
}

export default FlexCard
